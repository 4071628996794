const MENU_LIST = [
  {
    name: '币安',
    id: 'binance',
    activeTextColor: '#407dd9',
    subBgColor: '#6095e4',
    children: [
      {
        name: '比特币分分彩',
        id: 'BABTCFFC',
        icon: 'bitcoin',
        path: '/binance/BABTCFFC',
        freshT: 1, // min
        bannerObj: {
          title: '币安比特币分分彩（BTC/USDT）',
          subTitle: '采用全球最具公信力数字货币之一的交易所币安（binance）',
          desc: '每分钟比特币总成交额所得出数据（取至小数点后两位），使用最公正的第三方机构所产生出的数据，轻易验证，数据历史可查询，肉眼可验证。',
          volLabel: '比特币成交额：',
          from: '*所有数据均来自币安网'
        },
        desc: {
          highlight: `统计说明：币安交易所中的比特币每分钟成交量\n以成交额后四码含小数点后两位，由左至右分别为千百十个位\n成交额后五码含小数点后两位相加的总和，取尾数为万位`,
          example: `例： 当前比特币每分种成交量为：524358.28\n成交额后五码含小数点后两位数字相加之和：3+5+8+2+8=26 取尾数6为万位；\n以成交额后四码含小数点后两位：5828 做为千位、百位、十位、个位；\n组成号码为：65828`
        },
        apiExample: `[{"lottery":"BABTCFFC","issue":"20210903-0679","vol":"524358.28","code": "9,5,4,3,3","time":"2021-09-03 11:19:00"},{"lottery":"BABTCFFC","issue":"20210903-0679","vol":"524358.28","code": "9,5,4,3,3","time":"2021-09-03 11:19:00"},...]\n类型：Object数组； lottery：代号； issue：期数； code: 运算结果; vol：比特币成交额； time：统计时间；`
      },
      {
        name: '比特币五分彩',
        id: 'BABTC5FC',
        icon: 'bitcoin',
        path: '/binance/BABTC5FC',
        freshT: 5, // min
        bannerObj: {
          title: '币安比特币五分彩（BTC/USDT）',
          subTitle: '采用全球最具公信力数字货币之一的交易所币安（binance）',
          desc: '每五分钟比特币总成交额所得出数据（取至小数点后两位），使用最公正的第三方机构所产生出的数据，轻易验证，数据历史可查询，肉眼可验证。',
          volLabel: '比特币成交额：',
          from: '*所有数据均来自币安网'
        },
        desc: {
          highlight: `统计说明：币安交易所中的比特币每五分钟成交量\n以成交额后四码含小数点后两位，由左至右分别为千百十个位\n成交额后五码含小数点后两位相加的总和，取尾数为万位`,
          example: `例： 当前比特币每五分种成交量为：4970748.35\n成交额后五码含小数点后两位数字相加之和：7+4+8+3+5=27 取尾数7为万位；\n以成交额后四码含小数点后两位：4835 做为千位、百位、十位、个位；\n组成号码为：74835`
        },
        apiExample: `[{"lottery":"BABTC5FC","issue":"20210903-0679","vol":"524358.28","code": "9,5,4,3,3","time":"2021-09-03 11:19:00"},{"lottery":"BABTC5FC","issue":"20210903-0679","vol":"524358.28","code": "9,5,4,3,3","time":"2021-09-03 11:19:00"},...]\n类型：Object数组； lottery：代号； issue：期数； code: 运算结果; vol：比特币成交额； time：统计时间；`
      },
      {
        name: '以太币分分彩',
        id: 'BAETFFC',
        icon: 'ethereum',
        path: '/binance/BAETFFC',
        freshT: 1, // min
        bannerObj: {
          title: '币安以太币分分彩（ETH/USDT）',
          subTitle: '采用全球最具公信力数字货币之一的交易所币安（binance）',
          desc: '每分钟以太币总成交额所得出数据（取至小数点后两位），使用最公正的第三方机构所产生出的数据，轻易验证，数据历史可查询，肉眼可验证。',
          volLabel: '以太坊成交额：',
          from: '*所有数据均来自币安网'
        },
        desc: {
          highlight: `统计说明：币安交易所中的以太币每分钟成交量\n以成交额后四码含小数点后两位，由左至右分别为千百十个位\n成交额后五码含小数点后两位相加的总和，取尾数为万位`,
          example: `例： 当前以太币每分种成交量为：236699.32\n成交额后五码含小数点后两位数字相加之和：6+9+9+3+2=29 取尾数9为万位；\n以成交额后四码含小数点后两位：9932 做为千位、百位、十位、个位；\n组成号码为：99932`
        },
        apiExample: `[{"lottery":"BAETFFC","issue":"20210903-0679","vol":"524358.28","code": "9,5,4,3,3","time":"2021-09-03 11:19:00"},{"lottery":"BAETFFC","issue":"20210903-0679","vol":"524358.28","code": "9,5,4,3,3","time":"2021-09-03 11:19:00"},...]\n类型：Object数组； lottery：代号； issue：期数； code: 运算结果; vol：以太币成交额； time：统计时间；`
      },
      {
        name: '以太币五分彩',
        id: 'BAET5FC',
        icon: 'ethereum',
        path: '/binance/BAET5FC',
        freshT: 5, // min
        bannerObj: {
          title: '币安以太币五分彩（ETH/USDT）',
          subTitle: '采用全球最具公信力数字货币之一的交易所币安（binance）',
          desc: '每五分钟以太币总成交额所得出数据（取至小数点后两位），使用最公正的第三方机构所产生出的数据，轻易验证，数据历史可查询，肉眼可验证。',
          volLabel: '以太坊成交额：',
          from: '*所有数据均来自币安网'
        },
        desc: {
          highlight: `统计说明：币安交易所中的以太币每五分钟成交量\n以成交额后四码含小数点后两位，由左至右分别为千百十个位\n成交额后五码含小数点后两位相加的总和，取尾数为万位`,
          example: `例： 当前以太币每五分种成交量为：4970748.35\n成交额后五码含小数点后两位数字相加之和：7+4+8+3+5=27 取尾数7为万位；\n以成交额后四码含小数点后两位：4835 做为千位、百位、十位、个位；\n组成号码为：74835`
        },
        apiExample: `[{"lottery":"BAET5FC","issue":"20210903-0679","vol":"524358.28","code": "9,5,4,3,3","time":"2021-09-03 11:19:00"},{"lottery":"BAET5FC","issue":"20210903-0679","vol":"524358.28","code": "9,5,4,3,3","time":"2021-09-03 11:19:00"},...]\n类型：Object数组； lottery：代号； issue：期数； code: 运算结果; vol：以太币成交额； time：统计时间；`
      }
    ]
  },
  {
    name: '欧易',
    id: 'okx',
    activeTextColor: '#1199c4',
    subBgColor: '#3eb1d5',
    children: [
      {
        name: '比特币分分彩',
        id: 'OEBTCFFC',
        icon: 'bitcoin',
        path: '/okx/OEBTCFFC',
        freshT: 1, // min
        bannerObj: {
          title: '欧易比特币分分彩（BTC/USDT）',
          subTitle: '采用全球最具公信力数字货币之一的交易所欧易（okx）',
          desc: '每分钟比特币总成交额所得出数据（取至小数点后两位），使用最公正的第三方机构所产生出的数据，轻易验证，数据历史可查询，肉眼可验证。',
          volLabel: '比特币成交额：',
          from: '*所有数据均来自欧易网'
        },
        desc: {
          highlight: `统计说明：欧易交易所中的比特币每分钟成交量\n以成交额后四码含小数点后两位，由左至右分别为千百十个位\n成交额后五码含小数点后两位相加的总和，取尾数为万位`,
          example: `例： 当前比特币每分种成交量为：524358.28\n成交额后五码含小数点后两位数字相加之和：3+5+8+2+8=26 取尾数6为万位；\n以成交额后四码含小数点后两位：5828 做为千位、百位、十位、个位；\n组成号码为：65828`
        },
        apiExample: `[{"lottery":"OEBTCFFC","issue":"20210903-0679","vol":"524358.28","code": "9,5,4,3,3","time":"2021-09-03 11:19:00"},{"lottery":"OEBTCFFC","issue":"20210903-0679","vol":"524358.28","code": "9,5,4,3,3","time":"2021-09-03 11:19:00"},...]\n类型：Object数组； lottery：代号； issue：期数； code: 运算结果; vol：比特币成交额； time：统计时间；`
      },
      {
        name: '比特币五分彩',
        id: 'OEBTC5FC',
        icon: 'bitcoin',
        path: '/okx/OEBTC5FC',
        freshT: 5, // min
        bannerObj: {
          title: '欧易比特币五分彩（BTC/USDT）',
          subTitle: '采用全球最具公信力数字货币之一的交易所欧易（okx）',
          desc: '每五分钟比特币总成交额所得出数据（取至小数点后两位），使用最公正的第三方机构所产生出的数据，轻易验证，数据历史可查询，肉眼可验证。',
          volLabel: '比特币成交额：',
          from: '*所有数据均来自欧易网'
        },
        desc: {
          highlight: `统计说明：欧易交易所中的比特币每五分钟成交量\n以成交额后四码含小数点后两位，由左至右分别为千百十个位\n成交额后五码含小数点后两位相加的总和，取尾数为万位`,
          example: `例： 当前比特币每五分种成交量为：4970748.35\n成交额后五码含小数点后两位数字相加之和：7+4+8+3+5=27 取尾数7为万位；\n以成交额后四码含小数点后两位：4835 做为千位、百位、十位、个位；\n组成号码为：74835`
        },
        apiExample: `[{"lottery":"OEBTC5FC","issue":"20210903-0679","vol":"524358.28","code": "9,5,4,3,3","time":"2021-09-03 11:19:00"},{"lottery":"OEBTC5FC","issue":"20210903-0679","vol":"524358.28","code": "9,5,4,3,3","time":"2021-09-03 11:19:00"},...]\n类型：Object数组； lottery：代号； issue：期数； code: 运算结果; vol：比特币成交额； time：统计时间；`
      },
      {
        name: '以太币分分彩',
        id: 'OEETFFC',
        icon: 'ethereum',
        path: '/okx/OEETFFC',
        freshT: 1, // min
        bannerObj: {
          title: '欧易以太币分分彩（ETH/USDT）',
          subTitle: '采用全球最具公信力数字货币之一的交易所欧易（okx）',
          desc: '每分钟以太币总成交额所得出数据（取至小数点后两位），使用最公正的第三方机构所产生出的数据，轻易验证，数据历史可查询，肉眼可验证。',
          volLabel: '以太坊成交额：',
          from: '*所有数据均来自欧易网'
        },
        desc: {
          highlight: `统计说明：欧易交易所中的以太币每分钟成交量\n以成交额后四码含小数点后两位，由左至右分别为千百十个位\n成交额后五码含小数点后两位相加的总和，取尾数为万位`,
          example: `例： 当前以太币每分种成交量为：236699.32\n成交额后五码含小数点后两位数字相加之和：6+9+9+3+2=29 取尾数9为万位；\n以成交额后四码含小数点后两位：9932 做为千位、百位、十位、个位；\n组成号码为：99932`
        },
        apiExample: `[{"lottery":"OEETFFC","issue":"20210903-0679","vol":"524358.28","code": "9,5,4,3,3","time":"2021-09-03 11:19:00"},{"lottery":"OEETFFC","issue":"20210903-0679","vol":"524358.28","code": "9,5,4,3,3","time":"2021-09-03 11:19:00"},...]\n类型：Object数组； lottery：代号； issue：期数； code: 运算结果; vol：以太币成交额； time：统计时间；`
      },
      {
        name: '以太币五分彩',
        id: 'OEET5FC',
        icon: 'ethereum',
        path: '/okx/OEET5FC',
        freshT: 5, // min
        bannerObj: {
          title: '欧易以太币五分彩（ETH/USDT）',
          subTitle: '采用全球最具公信力数字货币之一的交易所欧易（okx）',
          desc: '每五分钟以太币总成交额所得出数据（取至小数点后两位），使用最公正的第三方机构所产生出的数据，轻易验证，数据历史可查询，肉眼可验证。',
          volLabel: '以太坊成交额：',
          from: '*所有数据均来自欧易网'
        },
        desc: {
          highlight: `统计说明：欧易交易所中的以太币每五分钟成交量\n以成交额后四码含小数点后两位，由左至右分别为千百十个位\n成交额后五码含小数点后两位相加的总和，取尾数为万位`,
          example: `例： 当前以太币每五分种成交量为：4970748.35\n成交额后五码含小数点后两位数字相加之和：7+4+8+3+5=27 取尾数7为万位；\n以成交额后四码含小数点后两位：4835 做为千位、百位、十位、个位；\n组成号码为：74835`
        },
        apiExample: `[{"lottery":"OEET5FC","issue":"20210903-0679","vol":"524358.28","code": "9,5,4,3,3","time":"2021-09-03 11:19:00"},{"lottery":"OEET5FC","issue":"20210903-0679","vol":"524358.28","code": "9,5,4,3,3","time":"2021-09-03 11:19:00"},...]\n类型：Object数组； lottery：代号； issue：期数； code: 运算结果; vol：以太币成交额； time：统计时间；`
      }
    ]
  },
  {
    name: '哈希',
    id: 'hash',
    activeTextColor: '#5342ba',
    subBgColor: '#6c5bd0',
    children: [
      {
        name: '哈希分分彩',
        id: 'HASHFFC',
        path: '/hash/HASHFFC',
        freshT: 1, // min
        bannerObj: {
          title: '哈希分分彩 TRX',
          desc: '哈希系列彩种采用完全去中心化的波场(Tron) 区块哈希值(Black Hash) 数据作为开奖号码，以去中心化为基础，奖源所采取的数据绝对公平，公正，不可篡改，数据公平可靠，亦可支持各位彩民随时查验！',
          from: '*所有数据均来自波场网'
        },
        desc: {
          highlight: `统计说明：取得的区块哈希值(Block Hash)使用最后五位数字，忽略字母作为开奖结果。（只看数字，不看字母）`,
          example: `例： 区块(Block)47440112，区块哈希为…ec4014ffeac60，那么哈希分分彩该期开奖结果为 0,1,4,6,0。`
        },
        apiExample: `[{"lottery":"HASHFFC","issue":"20210903-0679","block":"57268501","hash":"000000000369d91514f7f354a6221da6397ed14488d3a1b465fb731e233cfac2","code": "9,5,4,3,3","time":"2021-09-03 11:19:00"},{"lottery":"HASHFFC","issue":"20210903-0679","block":"57268501","hash":"000000000369d91514f7f354a6221da6397ed14488d3a1b465fb731e233cfac2","code": "9,5,4,3,3","time":"2021-09-03 11:19:00"},...]\n类型：Object数组； lottery：代号； issue：期数； code: 运算结果; block：区块； hash：区块哈希； time：统计时间；`
      },
      {
        name: '哈希三分彩',
        id: 'HASH3FC',
        path: '/hash/HASH3FC',
        freshT: 3, // min
        bannerObj: {
          title: '哈希三分彩 TRX',
          desc: '哈希系列彩种采用完全去中心化的波场(Tron) 区块哈希值(Black Hash) 数据作为开奖号码，以去中心化为基础，奖源所采取的数据绝对公平，公正，不可篡改，数据公平可靠，亦可支持各位彩民随时查验！',
          from: '*所有数据均来自波场网'
        },
        desc: {
          highlight: `统计说明：取得的区块哈希值(Block Hash)使用最后五位数字，忽略字母作为开奖结果。（只看数字，不看字母）`,
          example: `例： 区块(Block)47440112，区块哈希为…ec4014ffeac60，那么哈希分分彩该期开奖结果为 0,1,4,6,0。`
        },
        apiExample: `[{"lottery":"HASH3FC","issue":"20210903-0679","block":"57268501","hash":"000000000369d91514f7f354a6221da6397ed14488d3a1b465fb731e233cfac2","code": "9,5,4,3,3","time":"2021-09-03 11:19:00"},{"lottery":"HASH3FC","issue":"20210903-0679","block":"57268501","hash":"000000000369d91514f7f354a6221da6397ed14488d3a1b465fb731e233cfac2","code": "9,5,4,3,3","time":"2021-09-03 11:19:00"},...]\n类型：Object数组； lottery：代号； issue：期数； code: 运算结果; block：区块； hash：区块哈希； time：统计时间；`
      },
      {
        name: '哈希五分彩',
        id: 'HASH5FC',
        path: '/hash/HASH5FC',
        freshT: 5, // min
        bannerObj: {
          title: '哈希五分彩 TRX',
          desc: '哈希系列彩种采用完全去中心化的波场(Tron) 区块哈希值(Black Hash) 数据作为开奖号码，以去中心化为基础，奖源所采取的数据绝对公平，公正，不可篡改，数据公平可靠，亦可支持各位彩民随时查验！',
          from: '*所有数据均来自波场网'
        },
        desc: {
          highlight: `统计说明：取得的区块哈希值(Block Hash)使用最后五位数字，忽略字母作为开奖结果。（只看数字，不看字母）`,
          example: `例： 区块(Block)47440112，区块哈希为…ec4014ffeac60，那么哈希分分彩该期开奖结果为 0,1,4,6,0。`
        },
        apiExample: `[{"lottery":"HASH5FC","issue":"20210903-0679","block":"57268501","hash":"000000000369d91514f7f354a6221da6397ed14488d3a1b465fb731e233cfac2","code": "9,5,4,3,3","time":"2021-09-03 11:19:00"},{"lottery":"HASH5FC","issue":"20210903-0679","block":"57268501","hash":"000000000369d91514f7f354a6221da6397ed14488d3a1b465fb731e233cfac2","code": "9,5,4,3,3","time":"2021-09-03 11:19:00"},...]\n类型：Object数组； lottery：代号； issue：期数； code: 运算结果; block：区块； hash：区块哈希； time：统计时间；`
      }
    ]
  }
]

export { MENU_LIST }
