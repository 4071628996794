<template>
  <div class="app-footer">Copyright @ 2023 All Rights Reserved 加密统计 版权所有</div>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style lang="scss" scoped>
.app-footer {
  background-color: #434c77;
  height: 60px;
  font-weight: 500;
  font-size: 14px;
  color: #f3f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
