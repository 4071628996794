import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/binance/BABTCFFC'
  },
  {
    path: '/:menu/:id',
    name: 'Layout',
    component: () => import('@/views/binance')
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
