<template>
  <div class="app-header">
    <div class="container">
      <img src="@/assets/logo-title.png" alt="" class="pointer" @click="goHome" />
      <el-menu
        :default-active="activeMenu"
        class="app-header-menu"
        mode="horizontal"
        text-color="#4f4f4f"
        :active-text-color="activeTextColor"
        menu-trigger="click"
        @select="handleSelect"
      >
        <el-menu-item
          v-for="item in menuList"
          :key="item.id"
          :index="item.id"
          @click="menuClick(item)"
        >
          {{ item.name }}
        </el-menu-item>
      </el-menu>
    </div>
    <div
      v-if="currentMenu !== null && currentMenu.id"
      class="app-header-submenu"
      :style="{ backgroundColor: activeTextColor }"
    >
      <div class="container">
        <div
          v-for="item in currentMenu.children"
          :key="item.id"
          :class="['app-header-submenu-item pointer', { active: getCurrentSubMenu(item) }]"
          :style="{
            backgroundColor: currentMenu.subBgColor,
            color: getCurrentSubMenu(item) ? activeTextColor : '#fff'
          }"
          @click="clickSubMenu(item)"
        >
          <svg-icon v-if="item.icon === 'bitcoin'" icon-class="bitcoin" />
          <svg-icon v-if="item.icon === 'ethereum'" icon-class="ethereum" />
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MENU_LIST } from '@/consts'

export default {
  name: 'AppHeader',
  data() {
    return {
      activeMenu: '',
      activeTextColor: '',
      currentMenu: null,
      currentSubMenu: null,
      menuList: MENU_LIST
    }
  },
  watch: {
    $route: {
      handler(val) {
        if (val.fullPath !== '/') {
          const { menu, id } = val.params
          this.handleSelect(menu)
          this.currentMenu = this.menuList.find(item => item.id === menu)
          const subMenu = this.currentMenu.children.find(item => item.id === id)
          this.clickSubMenu(subMenu)
        }
      },
      immediate: true
    }
  },
  methods: {
    handleSelect(key) {
      this.activeMenu = key
      this.activeTextColor = this.menuList.find(item => item.id === key).activeTextColor
    },
    menuClick(item) {
      this.currentMenu = item
      this.clickSubMenu(item.children[0])
    },
    clickSubMenu(item) {
      this.currentSubMenu = item
      if (item.path !== this.$route.fullPath) this.$router.push(item.path)
    },
    getCurrentSubMenu(item) {
      return this.currentSubMenu !== null && this.currentSubMenu.id === item.id
    },
    goHome() {
      if (this.$route.fullPath !== '/binance/BABTCFFC') {
        this.$router.push('/')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-header {
  background-color: #fff;
  padding-top: 15px;
  user-select: none;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-menu {
    border-bottom: none;

    .el-menu-item {
      font-weight: 700;
      font-size: 20px;
      width: 186px;
      text-align: center;
    }
  }

  &-submenu {
    margin-top: 6px;
    height: 80px;
    .container {
      height: 100%;
      padding: 17px 0;
      color: #fff;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &-item {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 700;
      line-height: normal;
      padding: 11px 15px;
      border-radius: 10px;

      &.active {
        background-color: #fff !important;
      }

      &:hover {
        // background-color: #f00 !important;
      }

      &:not(:last-child) {
        margin-right: 16px;
      }

      .svg-icon {
        margin-right: 15px;
      }
    }
  }
}
</style>
