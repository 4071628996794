import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/index.scss' // global css
import dayjs from 'dayjs'

import App from './App.vue'
import router from './router'
import '@/icons' // svg icon

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.prototype.dayjs = dayjs

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
