<template>
  <div class="app">
    <AppHeader />
    <section class="app-content">
      <router-view />
    </section>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter
  }
}
</script>

<style lang="scss" scoped>
.app {
  max-width: 1920px;
  min-width: 1120px;
  width: 100%;
  margin: 0 auto;
}
</style>
